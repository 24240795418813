export enum EFilterTypes {
  CONTRACT_TYPE = "Contract Type",
  PROJECT_DIRECTORS = "Primary IT Project Director Abbreviation",
  PROJECT_SECONDARY_DIRECTORS = "Secondary IT Project Director Abbreviation",
  ORACLE_VENDORS = "Vendors Oracle",
  UAT_VENDORS = "Vendors GPNS",
  UAT_STATUS = "UAT Status",
  STATUS = "Status",
  SEARCH = "Search",
  DAYS= "Days"
}