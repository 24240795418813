import {
  EEndpoints,
  EKeyCallType,
  ICallType,
  standardColumns,
} from "../constants";
import { IFiltersState } from "../store/services";
import { EDataKeys } from "../types/TData";
import { EFilterTypes } from "../types/TFilter";

export const handleURL = () => {
  const tableUrl =
    EEndpoints.API_ENDPOINT +
    "/Project/select.json?" +
    "filter=" +
    "not%20IsNull(%5BActive%20Target%20Completion%20Date%5D)%20and%20%5BActive%20Target%20Completion%20Date%5D%3EAdjustYear(Today()%2C-2)&top=200&sort=Active%20Target%20Completion%20Date%2F%2FASC";
  return tableUrl;
};

export const handleURLWithFilters = (callType: ICallType, filter: IFiltersState) => {
  const filteredProjectDataColumns = standardColumns
    // If last 3 call note column, add callType specific column name for data pull
    .map((col) => {
      if (
        !callType[EKeyCallType.ID]?.includes("Default") &&
        col.columnId === 21
      )
        return undefined;
      if (col.columnId === 21) {
        return {
          ...col,
          id: callType[EKeyCallType.NOTE],
        };
      } else {
        return col;
      }
    })
    .filter((el) => el)
    // Filter only callType specific columns
    .filter((col) =>
      JSON.parse(callType[EKeyCallType.CUSTOM_FILTERS]).includes(col?.columnId)
    )
    // Remove columns that are not meant to be used for data pull from COMPASS
    .filter((col) => col?.doNotPullOutOfTheCOMPASS !== true);
 
  const projectUrlColumns = filteredProjectDataColumns
    .map((col) => encodeURIComponent(col?.id || ""))
    .concat(
      [
        encodeURIComponent("Related Site"),
        encodeURIComponent("GD Project #"),
        encodeURIComponent("UAT Date"),
        encodeURIComponent("Support Charge Started"),
        encodeURIComponent("UAT Note"),
        encodeURIComponent("Delay Ownership"),
        encodeURIComponent("Delay Reason"),
        encodeURIComponent(`${EDataKeys.OPERA_TYPE}`),
        encodeURIComponent(`${EDataKeys.OPERA_SC}`),
        encodeURIComponent(`${EDataKeys.OPERA_MULTI_PROPERTY}`),
        encodeURIComponent(`${EDataKeys.OPERA_CONFIG_DATE}`),
        encodeURIComponent(`${EDataKeys.OPERA_COUNTDOWN_DATE}`),
        encodeURIComponent(`${EDataKeys.LOCK_COUNTDOWN_DATE}`),
        encodeURIComponent(`${EDataKeys.OCI_ENVIRONMENT}`),
        encodeURIComponent(`${EDataKeys.VENDORS_POS}`),
        encodeURIComponent(`${EDataKeys.POS_VERSION}`),
      ])
    .join("&column=");


  const filters: string[] = [];

  if (filter[EFilterTypes.CONTRACT_TYPE]) {
    filters.push(`[Contract Type]="${filter[EFilterTypes.CONTRACT_TYPE]}"`);
  }
  if (filter[EFilterTypes.PROJECT_DIRECTORS]) {
    filters.push(`[Primary IT Project Director Abbreviation]="${filter[EFilterTypes.PROJECT_DIRECTORS]}"`);
  }

   if (filter[EFilterTypes.PROJECT_SECONDARY_DIRECTORS]) {
    filters.push(`[Secondary IT Project Director Abbreviation]="${filter[EFilterTypes.PROJECT_SECONDARY_DIRECTORS]}"`);
  }
  if (filter[EFilterTypes.ORACLE_VENDORS]) {
    filters.push(`[Vendors Oracle]="${filter[EFilterTypes.ORACLE_VENDORS]}"`)
  }
  if (filter[EFilterTypes.UAT_VENDORS]) {
    filters.push(`[Vendors GPNS]="${filter[EFilterTypes.UAT_VENDORS]}"`)
  }

  if (filter[EFilterTypes.UAT_STATUS]) {
    filters.push(`[UAT Status]="${filter[EFilterTypes.UAT_STATUS]}"`)
  }

  if (filter[EFilterTypes.STATUS].length) {
    const statusFilterString = filter[EFilterTypes.STATUS].map(item => `([Status] = "${item}")`).join(" or ");
    filters.push(`(${statusFilterString})`);
  }

  const [from, to] = filter[EFilterTypes.DAYS] || [];

  if (from !== null) {
    filters.push(`[Days to Project Target Date]>${from}d`)
  }

  if (to !== null) {
    filters.push(`[Days to Project Target Date]<${to}d`)
  }

  const filterStart = filters.length > 0 ? " and " : "";
  let filterCompose =
    filters.length > 0
      ? filters.join(` ${filterStart}`) + filterStart
      : filterStart;

  let filterSearch = "";

  if(filter.Search) {
    filterSearch = filteredProjectDataColumns
      .filter((column) => !column?.isDate && !column?.doNotPullOutOfTheCOMPASS && !column?.isNumeric && column?.id)
      .map((column) => `(Contains([${encodeURIComponent(column?.id || "")}], "${filter.Search}"))`)
      .join( " or ");

    filterCompose += `((${filterSearch})) and `;
  }
  const tableUrl =
    EEndpoints.API_ENDPOINT +
    "/Project/select.json?column=" +
    projectUrlColumns +
    "&filter=" +
    filterCompose +
    "not%20IsNull(%5BActive%20Target%20Completion%20Date%5D)&top=200&sort=Active%20Target%20Completion%20Date%2F%2FASC";
  return tableUrl;
};

export const handleURLUser = () => {
  const localUser = localStorage.getItem("user");
  return (
    "/User/select.json?filter=%5BFull%20Name%5D%3D%22" +
    encodeURIComponent(localUser || "undefined") +
    "%22"
  );
};
