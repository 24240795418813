import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { EFilterTypes } from "../../types/TFilter";
import { setType } from "./activeType";
import { EKeyCallType, dataCallTypes } from "../../constants";

export interface IFiltersState {
    [EFilterTypes.CONTRACT_TYPE]: string,
    [EFilterTypes.ORACLE_VENDORS]: string,
    [EFilterTypes.PROJECT_DIRECTORS]: string,
    [EFilterTypes.PROJECT_SECONDARY_DIRECTORS]: string,
    [EFilterTypes.STATUS]: string[],
    [EFilterTypes.UAT_STATUS]: string,
    [EFilterTypes.UAT_VENDORS]: string,
    [EFilterTypes.SEARCH]: string,
    [EFilterTypes.DAYS]: [number | null, number | null],
}

export const initialState: IFiltersState = {
    [EFilterTypes.CONTRACT_TYPE]: "",
    [EFilterTypes.ORACLE_VENDORS]: "",
    [EFilterTypes.PROJECT_DIRECTORS]: "",
    [EFilterTypes.PROJECT_SECONDARY_DIRECTORS]: "",
    [EFilterTypes.STATUS]: [],
    [EFilterTypes.UAT_STATUS]: "",
    [EFilterTypes.UAT_VENDORS]: "",
    [EFilterTypes.SEARCH]: "",
    [EFilterTypes.DAYS]: [-30, 730]
};

export const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<{ name: keyof IFiltersState, value: IFiltersState[keyof IFiltersState] }>,
        ) => {
            const { name, value } = action.payload;

            switch (name) {
                case EFilterTypes.CONTRACT_TYPE:
                    state[EFilterTypes.CONTRACT_TYPE] = value as IFiltersState[EFilterTypes.CONTRACT_TYPE];
                    break;
                case EFilterTypes.ORACLE_VENDORS:
                    state[EFilterTypes.ORACLE_VENDORS] = value as IFiltersState[EFilterTypes.ORACLE_VENDORS];
                    break;
                case EFilterTypes.PROJECT_DIRECTORS:
                    state[EFilterTypes.PROJECT_DIRECTORS] = value as IFiltersState[EFilterTypes.PROJECT_DIRECTORS];
                    break;
                case EFilterTypes.PROJECT_SECONDARY_DIRECTORS:
                    state[EFilterTypes.PROJECT_SECONDARY_DIRECTORS] = value as IFiltersState[EFilterTypes.PROJECT_SECONDARY_DIRECTORS];
                    break;
                case EFilterTypes.STATUS:
                    state[EFilterTypes.STATUS] = value as IFiltersState[EFilterTypes.STATUS];
                    break;
                case EFilterTypes.UAT_STATUS:
                    state[EFilterTypes.UAT_STATUS] = value as IFiltersState[EFilterTypes.UAT_STATUS];
                    break;
                case EFilterTypes.UAT_VENDORS:
                    state[EFilterTypes.UAT_VENDORS] = value as IFiltersState[EFilterTypes.UAT_VENDORS];
                    break;
                case EFilterTypes.SEARCH:
                    state[EFilterTypes.SEARCH] = value as IFiltersState[EFilterTypes.SEARCH];
                    break;
                case EFilterTypes.DAYS:
                    state[EFilterTypes.DAYS] = [...value] as IFiltersState[EFilterTypes.DAYS];
                    break;
            }
        },
    },
    extraReducers(builder) {
        builder.addMatcher(setType.match, (state, action) => {
            const customCallType = !dataCallTypes.find(item => item.Id === action.payload.Id);
            state = {
                ...initialState,
                Days: [-30, 730]
            }

            const { payload: currentType } = action;
            if (customCallType) {
                try {
                    state[EFilterTypes.CONTRACT_TYPE] = currentType[EKeyCallType.CONTRACT_TYPE] || "";
                    state[EFilterTypes.DAYS] = JSON.parse(currentType[EKeyCallType.DAYS]) || [-30, 730];
                    state[EFilterTypes.PROJECT_DIRECTORS] = currentType[EKeyCallType.PROJECT_DIRECTORS] || "";
                    state[EFilterTypes.ORACLE_VENDORS] = currentType[EKeyCallType.ORACLE_VENDOR] || "";
                    state[EFilterTypes.UAT_STATUS]  = currentType[EKeyCallType.UAT_STATUS] || "";
                    state[EFilterTypes.UAT_VENDORS] = currentType[EKeyCallType.UAT_VENDOR] || "";
                    state[EFilterTypes.STATUS] = JSON.parse(currentType[EKeyCallType.STATUS] || "[]") || [];
                } catch(e) {
                    console.log(e, currentType[EKeyCallType.STATUS]);
                }
            } 
            return state;
        })
    },
});

export const { setFilter } =
    filtersSlice.actions;

export default filtersSlice.reducer;